<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
    >
      <v-card v-if="item">
        <v-card-title class="pa-0">
          <v-toolbar
          >
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ item.nombre }}
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list
            two-line
            subheader
          >
            <v-subheader>{{ $t('app.general.information') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.nombre }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.name') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.descripcion }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.description') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'PosicionesDetail',
    data () {
      return {
        dialog: false,
        item: false,
      }
    },
    mounted() {
      EventBus.$on('posiciones-detail', (item) => {
        this.item = item
        this.dialog = true
      })
    },
  }
</script>

<style scoped>

</style>
